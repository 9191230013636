import { createThirdwebClient, getContract } from "thirdweb";
import { defineChain } from "thirdweb/chains";

const client = createThirdwebClient({
  clientId: process.env.REACT_APP_CLIENT_ID,
  secretKey: process.env.REACT_APP_SECRET_KEY,
});

const chain = defineChain(parseInt(process.env.REACT_APP_CHAIN_ID, 10));

const tokenDropContract = getContract({
  client,
  chain,
  address: process.env.REACT_APP_TOKEN_AIRDROP_CONTRACT_ADDRESS,
  contractType: "token-drop",
});

const presaleContract = getContract({
  client,
  chain,
  address: process.env.REACT_APP_TOKEN_PRESALE_CONTRACT_ADDRESS,
  contractType: "presale-contract",
});

export { client, tokenDropContract, presaleContract };
