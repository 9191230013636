import React from "react";
import { useSendTransaction } from "thirdweb/react";
import { prepareContractCall } from "thirdweb";
import { tokenDropContract } from "../../thirdwebClient";
import Spinner from "react-spinner-material";
import Button from "../utils/CustomButton";
import "../../styles/Thirdweb.css";

const ClaimAirdrop = ({ walletAddress, showToast }) => {
  const { mutate: sendTransaction, isLoading, error } = useSendTransaction();
  const disabled = true;

  const handleClaimAirdrop = () => {
    try {
      const transaction = prepareContractCall({
        contract: tokenDropContract,
        method: "function claimAirdrop(address _claimer)",
        params: [walletAddress],
      });

      sendTransaction(transaction, {
        onSuccess: () => {
          showToast({
            key: 2,
            type: "notification",
            severity: "success",
            message: "Airdrop claimed successfully",
          });
        },
        onError: (err) => {
          showToast({
            key: 3,
            type: "notification",
            severity: "error",
            message: err.message,
          });
        },
      });
    } catch (e) {
      showToast({
        key: 3,
        type: "notification",
        severity: "error",
        message: e.message,
      });
    }
  };

  return (
    <div>
      <div className="airdrop-text-content">
        <h2>Steps to Claim Free Airdrop</h2>
        <p>Complete these steps to claim 20,000 FFK</p>
        <p className="step">
          1. Follow{" "}
          <span>
            {" "}
            <a
              href="https://x.com/GrannyNakamoto"
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              #CryptoGranny
            </a>
          </span>{" "}
          on Twitter.
        </p>
        <p className="step">2. Have a wallet, created before 4th July 2024.</p>
        <p className="step">3. Have atleast 100 MATIC in the Wallet.</p>
        <p className="step note">
          NOTE: This Airdrop is free, you just have to pay for transaction in
          MATIC.
        </p>
      </div>
      <Button
        text={isLoading ? "Claiming..." : "Claim Airdrop"}
        onClick={handleClaimAirdrop}
        disabled={isLoading}
        // disabled={disabled}
        className="custom-button purchase-btn"
      />
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Spinner
            size={40}
            spinnerColor={"#fff"}
            spinnerWidth={2}
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default ClaimAirdrop;
