import React from "react";

const PurchaseFFK = () => {
  return (
    <div>
      <div className="airdrop-text-content">
        <h2>PRESAL IS LIVE NOW!</h2>
        <p>Mint your own coins here!</p>
      </div>
      <iframe
        title="mint"
        src="https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc20.html?contract=0xd7C4b7EAEa10c3A0AEAb2c5Ca889Aed4867E20c9&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fpolygon%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=0e1d96cc1470d9fcce7ebc61682e6cb2&theme=dark&primaryColor=blue"
        width="600px"
        height="600px"
        style={{ maxWidth: "100%" }}
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default PurchaseFFK;
