import React, { useState } from "react";
import "../../styles/PurchaseCard.css";
import { Link } from "react-router-dom";
import ConnectWallet from "../thirdweb/ConnectWallet";
import Toast from "../utils/Toast";

const PurchaseCard = () => {
  const [toast, setToast] = useState(null);

  const showToast = (notification) => {
    setToast(notification);
    setTimeout(() => setToast(null), 3000);
  };

  return (
    <div className="section-container purchase-card-container">
      <div className="purchase-card">
        <div className="card-header">
          <h1>AIRDROP STARTS 7 July</h1>
        </div>
        <div className="card-body">
          <h3>Come Grab your Flying Fucks 🚀</h3>
          <p>1 $FFK = $0.00025</p>
          <div className="info">
            <p>Import FFK Token address to your wallet</p>
            <p>
              <br />
            </p>
            <p>FFK: 0xd7C4b7EAEa10c3A0AEAb2c5Ca889Aed4867E20c9</p>
          </div>

          <ConnectWallet onConnect={() => {}} showToast={showToast} />
        </div>
        <div className="card-footer">
          <p>
            Powered by{" "}
            <span className="web3-payments">
              <a
                href="https://x.com/GrannyNakamoto"
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                #CryptoGranny
              </a>
              's Cookies
            </span>
          </p>
        </div>
        {toast && <Toast notification={toast} onClose={() => setToast(null)} />}
      </div>
    </div>
  );
};

export default PurchaseCard;
