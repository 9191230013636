import React from "react";
import "../../styles/Footer.css";
// import CustomButton from "../utils/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faRedditAlien,
//   faXTwitter,
//   faTelegram,
// } from "@fortawesome/free-brands-svg-icons";

import { Link } from "react-router-dom";
import { FOOTER_ITEMS } from "../../constants";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>{FOOTER_ITEMS[0].title}</h3>
          <p>{FOOTER_ITEMS[0].subtitle}</p>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            {FOOTER_ITEMS[0].quickLinks.map((link) => (
              <li key={link.key}>
                <Link to={link.to}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul>
            {FOOTER_ITEMS[0].contact_us.map((item) =>
              item.type === "mail" ? (
                <p>
                  <a href="mailto:support@theflyingfuck.com" className="link">
                    {item.item}
                  </a>
                </p>
              ) : (
                <li key={item.key}>{item.item}</li>
              )
            )}
          </ul>
        </div>
        <div className="footer-column">
          <h3>Our Socials</h3>
          {/* <form className="subscribe-form">
            <input type="email" placeholder="Enter your email" />
            <CustomButton text="Subscribe" className="custom-button" />
          </form> */}
          <div className="social-icons">
            {FOOTER_ITEMS[0].socials.map((item) => (
              <a key={item.key} href={item.to} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={item.icon} className="icon" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
