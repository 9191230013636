import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoNotificationsSharp } from "react-icons/io5";
import "../../styles/Navbar.css";
import TwitterLogo from "../../assets/icons/twitter.svg";
import TelegramLogo from "../../assets/icons/telegram.svg";
// import CustomButton from "../utils/CustomButton";
import { NAV_LINKS } from "../../constants";
import NotificationPanel from "./NotificationPanel";
import { NOTIFICATION_ITEMS } from "../../constants";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState(NOTIFICATION_ITEMS);
  const notificationPanelRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToSection(targetId);
      }, 100);
    } else {
      scrollToSection(targetId);
    }
    setIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const scrollToSection = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const yOffset = -80; // Adjust this value based on your navbar height
      const y =
        targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleDismiss = (index) => {
    const newNotifications = [...notifications];
    newNotifications.splice(index, 1);
    setNotifications(newNotifications);
  };

  const handleOutsideClick = (event) => {
    if (
      notificationPanelRef.current &&
      !notificationPanelRef.current.contains(event.target) &&
      !event.target.closest(".notification-icon")
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <header className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <Link to="/" className="logo">
        Flying FUCK
      </Link>
      <nav className={`nav-menu ${isOpen ? "open" : ""}`}>
        <ul>
          {NAV_LINKS.map((link) => (
            <li key={link.key}>
              {link.external ? (
                <a href={link.to} target="_blank" rel="noopener noreferrer">
                  {link.label}
                </a>
              ) : (
                <Link
                  to={link.to}
                  onClick={(e) => handleLinkClick(e, link.key)}
                >
                  {link.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <div className={`social-icons ${isOpen ? "mobile-visible" : ""}`}>
        <a
          href="https://x.com/GrannyNakamoto"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={TwitterLogo} alt="Twitter Logo" />
        </a>
        <a
          href="https://t.me/+sq9bj0RyO5U4M2Jh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={TelegramLogo} alt="Telegram Logo" />
        </a>
      </div>
      <div className="notification-bell-container">
        <IoNotificationsSharp
          className={`notification-icon ${showNotifications ? "active" : ""}`}
          onClick={() => setShowNotifications(!showNotifications)}
        />
        {notifications.length > 0 && (
          <span className="notification-badge">{notifications.length}</span>
        )}
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      {showNotifications && (
        <NotificationPanel
          ref={notificationPanelRef}
          notifications={notifications}
          onDismiss={handleDismiss}
        />
      )}
    </header>
  );
}

export default Navbar;
