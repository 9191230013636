import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { DEFAME_ITEMS } from "../../constants";
import { RenderContent } from "../utils/utilities";
import "../../styles/DeFame.css";

const DeFame = () => {
  return (
    <div className="section-container defame-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {DEFAME_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {DEFAME_ITEMS[0].subtitle}
      </Typography>
      <Card className="defame-card">
        <Typography
          variant="body1"
          component="div"
          gutterBottom
          className="content"
        >
          {RenderContent(DEFAME_ITEMS[0].content)}
        </Typography>
      </Card>
    </div>
  );
};

export default DeFame;
