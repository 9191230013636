import React, { useState } from "react";
import { ConnectButton } from "thirdweb/react";
import { createWallet } from "thirdweb/wallets";
import { client } from "../../thirdwebClient";
import ClaimAirdrop from "./ClaimAirdrop";
import PurchaseFFK from "./PurchaseFFK";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../styles/Thirdweb.css";

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("me.rainbow"),
];

const ConnectWallet = ({ onConnect, showToast }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [value, setValue] = useState(0);

  const handleConnect = async (wallet) => {
    const account = await wallet.getAccount();
    setWalletAddress(account.address);
    onConnect(account.address);
    showToast({
      key: 1,
      type: "notification",
      severity: "success",
      message: "Wallet Connected Successfully",
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            width: "100%",
            borderTop: 1,
            borderColor: "divider",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          <Tabs
            value={value}
            className="tabs"
            onChange={handleChange}
            aria-label="FFK Token"
          >
            <Tab label="AIRDROP" className="tab" />
            <Tab label="Presale" className="tab" />
            <Tab label="Staking" className="tab" />
          </Tabs>
          <div
            role="tabpanel"
            hidden={value !== 0}
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
          >
            {value === 0 && (
              <Box sx={{ p: 3 }}>
                <ConnectButton
                  client={client}
                  wallets={wallets}
                  onConnect={handleConnect}
                />

                {walletAddress && (
                  <ClaimAirdrop
                    walletAddress={walletAddress}
                    showToast={showToast}
                  />
                )}
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={value !== 1}
            id={`simple-tabpanel-1`}
            aria-labelledby={`simple-tab-1`}
          >
            {value === 1 && (
              <Box sx={{ p: 3 }}>
                <PurchaseFFK showToast={showToast} />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={value !== 2}
            id={`simple-tabpanel-2`}
            aria-labelledby={`simple-tab-2`}
          >
            {value === 2 && (
              <Box sx={{ p: 3 }}>
                <h2>Stake #FFK to get #SHIT</h2>
                <h4>Staking Starts Soon</h4>
              </Box>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ConnectWallet;
