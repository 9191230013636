import React, { forwardRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import "../../styles/NotificationPanel.css";

const NotificationPanel = forwardRef(({ notifications, onDismiss }, ref) => {
  return (
    <div
      ref={ref}
      className="notification-panel animate__animated animate__fadeInRight"
    >
      {notifications.length > 0 ? (
        <ul className="notifications">
          {notifications.map((notification, index) => (
            <li key={index}>
              {notification.type === "tweet" ? (
                <Card className="notification-card">
                  <CardHeader
                    avatar={
                      <Avatar
                        src={notification.image}
                        alt={notification.title}
                      />
                    }
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={() => onDismiss(index)}
                      >
                        <FaTimes className="toast-close" />
                      </IconButton>
                    }
                    title={notification.title}
                    subheader={notification.time}
                    className="toast-header"
                  />
                  <CardContent className="toast-content">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className="toast-text"
                    >
                      {notification.message}
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <Alert
                  severity={notification.severity || "info"}
                  onClose={() => onDismiss(index)}
                  className="notification-alert"
                >
                  <AlertTitle>{notification.title}</AlertTitle>
                  {notification.message}
                </Alert>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-notifications">You have read all notifications</div>
      )}
    </div>
  );
});

export default NotificationPanel;
