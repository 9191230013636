import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Navbar from "../common/Navbar";
import { BLOG_POSTS } from "../../constants";
import Footer from "../common/Footer";
import "../../styles/Blogs.css";
import BlogCard from "./BlogCard";

const BlogPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  // const handleClickOpen = (post) => {
  //   setSelectedPost(post);
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setSelectedPost(null);
  };

  return (
    <>
      <Navbar />
      <div className="blog-page">
        <Typography variant="h4" component="div" gutterBottom className="title">
          {BLOG_POSTS[0].title}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          className="subtitle"
        >
          {BLOG_POSTS[0].subtitle}
        </Typography>
        <div className="blog-cards">
          {BLOG_POSTS[0].posts.map((post, index) => (
            <BlogCard
              imageUrl={post.image}
              subtitle={post.subtitle}
              title={post.title}
              content={post.content}
              dateTime={post.dateTime}
            />
          ))}
        </div>
        <Dialog open={open} onClose={handleClose} className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={handleClose}>
              &times;
            </button>
            {selectedPost && (
              <>
                <img
                  src={selectedPost.image}
                  alt={selectedPost.title}
                  className="popup-image"
                />
                <DialogTitle>{selectedPost.title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{selectedPost.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </>
            )}
          </div>
        </Dialog>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
