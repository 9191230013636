import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// component
import Landing from "./components/sections/Landing";
import PurchaseCard from "./components/sections/PurchaseCard";
import Footer from "./components/common/Footer";
import Tokenomics from "./components/sections/Tokenomics";
import FAQ from "./components/sections/FAQ";
import Roadmap from "./components/sections/Roadmap";
import DeFame from "./components/sections/DeFame";
import About from "./components/sections/About";
import DeFameTools from "./components/sections/DeFameTools";
import Nfts from "./components/sections/Nfts";
import Story from "./components/sections/Story";
import Blogs from "./components/sections/Blogs";
import BlogPage from "./components/blogs/BlogPage";
import Navbar from "./components/common/Navbar";
import Toast from "./components/utils/Toast";
import BigBlogCard from "./components/blogs/BigBlogCard";
import LoadingScreen from "./components/common/LoadingScreen";
import { ScrollToTop } from "./components/utils/utilities";
import { initializeGA, logPageView } from "./analytics";
import ChatBot from "./components/utils/ChatBot";
import { useLocation } from "react-router-dom";
// constants
import { NOTIFICATION_ITEMS } from "./constants";

// styles
import "./styles/App.css";
import { StyledEngineProvider } from "@mui/material/styles";

function App() {
  useEffect(() => {
    initializeGA();
  }, []);

  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);

  const [isLoading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(true);
  const [toastClosedManually, setToastClosedManually] = useState(false);

  const handleCloseToast = () => {
    setShowToast(false);
    setToastClosedManually(true);
  };

  const handleScroll = () => {
    if (toastClosedManually) return;

    const section = document.querySelector(".landing-section"); // Adjust this selector to your section
    if (section) {
      const sectionHeight = section.clientHeight;
      const scrollPosition = window.scrollY;
      if (scrollPosition > sectionHeight / 2) {
        setShowToast(false);
        setToastClosedManually(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toastClosedManually]);

  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        {isLoading ? (
          <LoadingScreen setLoading={setLoading} />
        ) : (
          <>
            <Navbar />
            <ScrollToTop />
            <div className="main-content">
              {showToast && (
                <Toast
                  notification={NOTIFICATION_ITEMS[0]}
                  onClose={handleCloseToast}
                />
              )}
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blog/:title" element={<BigBlogCard />} />
              </Routes>
            </div>
          </>
        )}
      </div>
    </StyledEngineProvider>
  );
}

const LandingPage = () => (
  <>
    <Landing />
    {/* <ChatBot /> */}

    <div id="purchase-card" className="section">
      <PurchaseCard />
    </div>

    <div id="story" className="section">
      <Story />
    </div>

    <div id="defame" className="section">
      <DeFame />
      <DeFameTools />
    </div>

    <div id="roadmap" className="section">
      <Roadmap />
    </div>

    <div id="tokenomics" className="section">
      <Tokenomics />
    </div>

    <div id="nfts" className="section">
      <Nfts />
    </div>

    <div id="about" className="section">
      <About />
    </div>

    <div id="blogs" className="section">
      <Blogs />
    </div>

    <div id="faqs" className="section">
      <FAQ />
    </div>

    <Footer />
  </>
);

export default App;
