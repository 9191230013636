import React from "react";
import { Typography } from "@mui/material";
import "../../styles/Nfts.css";
import { NFT_ITEMS } from "../../constants";
import NftCard from "../utils/NftCard";

export default function Nfts() {
  return (
    <div className="section-container nfts-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {NFT_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {NFT_ITEMS[0].subtitle}
      </Typography>
      <div className="slider">
        <div className="slide-track">
          {NFT_ITEMS[0].nfts.map((nft, index) => (
            <div className="slide" key={index}>
              <NftCard image={nft.image} title={nft.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
