import React from "react";
import PropTypes from "prop-types";
import "../../styles/CustomButton.css";

const CustomButton = ({ text, onClick, disabled, className }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`custom-button ${className}`}
  >
    {text}
  </button>
);

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

CustomButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: "",
};

export default CustomButton;
