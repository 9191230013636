import React from "react";
import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import { ROADMAP_ITEMS } from "../../constants";
import roadmapImage from "../../assets/images/roadmap/roadmap.png";
import roadmapImageMobile from "../../assets/images/roadmap/roadmap_mobile.png";
import "../../styles/Roadmap.css";

export default function CustomizedTimeline() {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <div className="section-container roadmap-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {ROADMAP_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {ROADMAP_ITEMS[0].subtitle}
      </Typography>
      <Card>
        <CardContent>
          <img
            src={isMobile ? roadmapImageMobile : roadmapImage}
            alt="Tokenomics 1"
            className="roadmap-image"
          />
        </CardContent>
      </Card>
    </div>
  );
}
