import React, { useState } from "react";
import "../../styles/Landing.css";
import CoinFlip from "../utils/CoinFlip";
import CustomButton from "../utils/CustomButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LANDING_ITEMS } from "../../constants";

function Landing() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const handleWhitepaperClick = () => {
    window.open("/assets/docs/Whitepaper.pdf", "_blank");
  };

  const scrollToSection = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const yOffset = -80; // Adjust this value based on your navbar height
      const y =
        targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToSection(targetId);
      }, 100);
    } else {
      scrollToSection(targetId);
    }
    setIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <>
      <section className="landing-section">
        <div className="landing-content-wrapper">
          <div className="landing-coinflip-wrapper">
            <CoinFlip />
            <div className="glowing-container">
              <div className="rectangle"></div>
              <div className="glowing-circle"></div>
            </div>
          </div>
          <div className="landing-content">
            <div className="header-name">{LANDING_ITEMS[0].title}</div>
            <div className="styled-header">{LANDING_ITEMS[0].heading}</div>
            <div className="sub-header">{LANDING_ITEMS[0].subtitle}</div>
            <div className="landing-buttons">
              <CustomButton
                text={<span>WHITEPAPER</span>}
                className="custom-button custom-button-lg"
                onClick={handleWhitepaperClick}
              />
              <CustomButton
                text={<span>BUY NOW</span>}
                className="custom-button custom-button-lg-2"
                onClick={(e) => handleLinkClick(e, "purchase-card")}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
