import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const RenderContent = (content) => {
  if (!content) return null;
  return content.split("\n").map((item, index) => <p key={index}>{item}</p>);
};
