import React from "react";
import "../../styles/CoinFlip.css";

const CoinFlip = () => {
  return (
    <div className="container">
      <div id="coin" className="continuous-rotate">
        <div id="heads" className="heads"></div>
        <div id="tails" className="tails"></div>
      </div>
    </div>
  );
};

export default CoinFlip;
