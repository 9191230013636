import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";
import "../../styles/Blogs.css";
import { BLOG_POSTS } from "../../constants";
import BlogCard from "../blogs/BlogCard";
import CustomButton from "../utils/CustomButton";

const Blogs = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(3);
  const totalBlogs = BLOG_POSTS[0].posts.length;

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth <= 768) {
        setCardsToShow(1);
      } else {
        setCardsToShow(3);
      }
    };

    updateCardsToShow();
    window.addEventListener("resize", updateCardsToShow);
    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  const handleViewAll = () => {
    navigate("/blogs");
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalBlogs - cardsToShow : prevIndex - cardsToShow
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalBlogs - cardsToShow ? 0 : prevIndex + cardsToShow
    );
  };

  const getDisplayedBlogs = () => {
    if (totalBlogs <= cardsToShow) {
      return BLOG_POSTS[0].posts;
    }

    const displayedBlogs = [];
    for (let i = 0; i < cardsToShow; i++) {
      displayedBlogs.push(BLOG_POSTS[0].posts[(currentIndex + i) % totalBlogs]);
    }
    return displayedBlogs;
  };

  const handleReadMore = (post) => {
    navigate(`/blog/${post.id}`, { state: post });
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrevious(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getDotClass = (index) => {
    return index === Math.floor(currentIndex / cardsToShow)
      ? "dot active"
      : "dot";
  };

  return (
    <div className="section-container blogs-container" {...swipeHandlers}>
      <Typography variant="h4" component="div" gutterBottom className="title">
        {BLOG_POSTS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {BLOG_POSTS[0].subtitle}
      </Typography>
      <div className="blog-navigation">
        <IconButton onClick={handlePrevious} className="arrow-icon">
          <ArrowBack />
        </IconButton>
        <div className="blog-cards-container">
          {getDisplayedBlogs().map((post, index) => (
            <BlogCard
              key={index}
              imageUrl={post.image}
              title={post.title}
              subtitle={post.subtitle}
              description={post.content}
              dateTime={post.dateTime}
              onReadMore={() => handleReadMore(post)}
            />
          ))}
        </div>
        <IconButton onClick={handleNext} className="arrow-icon">
          <ArrowForward />
        </IconButton>
      </div>
      <div className="dots-container">
        {Array(Math.ceil(totalBlogs / cardsToShow))
          .fill()
          .map((_, index) => (
            <span key={index} className={getDotClass(index)} />
          ))}
      </div>
      <div className="view-all-container">
        <CustomButton
          variant="contained"
          text="View All"
          className="custom-button"
          onClick={handleViewAll}
          endIcon={<ArrowForward />}
        />
      </div>
    </div>
  );
};

export default Blogs;
